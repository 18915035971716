/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 volk_te-37_rim.glb 
Author: lukejdavis3 (https://sketchfab.com/lukejdavis3)
License: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
Source: https://sketchfab.com/3d-models/volk-te-37-rim-0b0e2fe6a72d4801b454d94aaa4ac569
Title: Volk Te-37 Rim
*/

import React, { useEffect } from 'react'
import { useGLTF } from '@react-three/drei'




export function VolkTe37({ wheelColor, setWheelColor, material, ...props }) {

  // const { nodes, materials } = useGLTF('https://pub-1e74d0bc9c59443f90fdc7adddccf38e.r2.dev/volk_te-37_rim.glb')
  // useGLTF.preload('https://pub-1e74d0bc9c59443f90fdc7adddccf38e.r2.dev/volk_te-37_rim.glb')

  const { nodes, materials } = useGLTF('models/volk_te-37_rim.glb')
  useGLTF.preload('models/volk_te-37_rim.glb')


  useEffect(() => {
    if (materials.material_0) {
      materials.material_0.color.setStyle(wheelColor)
    }
  }, [wheelColor, materials.material_0])

  useEffect(() => {
    if (material) {
      material.color.setStyle(wheelColor)
    }
  }, [wheelColor, material])

  return (
    <group {...props} dispose={null}>
      <group scale={1.69}>
        <mesh geometry={nodes.Object_2.geometry} material={material} />
      </group>
    </group>
  )
}


