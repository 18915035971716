
export const LoadingIndicator = () => {


  return (
    <div className="loading-indicator">

    </div>
  );

}